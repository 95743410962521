.main {
  background: url('../../assets/images/wallpaper.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  height: 100vh;
  width: 100vw;

  overflow: hidden;
  overflow-x: hidden;
  display: flex;
  justify-items: center;
  align-items: center;
}

.main-svg {
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  left: 0;
  display: absolute;
}

.svg-element {
  position: absolute;
  opacity: 0;
}

.title-one {
  right: 0;
  display: absolute;
}

.second-svg {
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  left: 0;
  display: absolute;
}

.third-svg {
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  left: 0;
  display: absolute;
}
.title-two {
  left: 0;
  display: absolute;
}
.title-three {
  left: 0;
  display: absolute;
}
